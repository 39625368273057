<template>
  <v-card
    class="formulaire-fiche pa-4 overflow-y-hidden"
    :class="{ 'card-three': !detailledView }"
  >
<v-form ref="formFiche" lazy-validation>
      <div class="ma-4 formulaire-fiche--header">
        <h1>{{ $t("messagerie.text.form.parametrages") }}</h1>
        <v-divider></v-divider>
        <v-list>
          <v-container fluid>
            <v-text-field
              v-model="ficheSettings.general"
              :label="$t('messagerie.text.form.emailcopygeneral')"
              :placeholder="$t('messagerie.text.form.emailfield')"
            ></v-text-field>
          </v-container>
        </v-list>
        <v-spacer></v-spacer>
      </div>
      <div class="formulaire-fiche--footer">
        <v-row class="align-center">
          <v-col cols="12">
            <v-btn
              color="primary"
              @click="saveSettings()"
            >
              {{ $t("actions.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import MessagerieService from "@/Services/MessagerieService";
import { mapGetters } from "vuex";

export default {
  name: "HotlineSettingsForm",
  components: {
  },
  props: {
    detailledView: Boolean,
  },
  watch: {
    // fichesettings: {
    //   deep: true,
    //   handler() {
    //     this.$emit("input", this.fichesettings);
    //   },
    // },
  },
  computed: {
    ...mapGetters(["getBase"])
  },
  data() {
    return {
    ficheSettings: {}
    };
  },
  methods: {
    async saveSettings() {
            await MessagerieService.saveSettings({
              datas: this.ficheSettings
            }).then((res) => {
              console.log(res)
            });
          },
    async getSettings() {
            await MessagerieService.getSettings().then((res) => {
              if (res.datas != null)
                this.ficheSettings=res.datas
            });
          },
  },
  mounted() {
    this.getSettings()
  }
};
</script>

<style lang="scss">
.messagerie-chat--footer--wysiwyg {
  display: grid;
  grid-template-columns: 5fr 1fr;

  //noinspection CssUnusedSymbol
  .tiptap-vuetify-editor__content {
    max-height: 250px;

    img {
      display: block;
      max-width: 800px;
      max-height: 400px;
      width: auto;
      height: auto;
    }
  }
}
</style>
